import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { isNil } from "lodash";
import { Header, Content, Sidebar, Footer } from "components";
import { Box } from "@mui/material";
import { useResponsive } from "hooks/useResponsive";

const wrapperStyle = () => {
  return {
    display: "flex",
    minHeight: "calc(100vh - 132px)",
  };
};

const pageStyle = () => {
  return {
    display: "flex",
    flexDirection: "column",
  };
};

const AuthLayout = () => {
  const session = useSelector((state) => state.session.session);
  const navigate = useNavigate();
  const upSm = useResponsive("up", "sm");

  useEffect(() => {
    if (isNil(session)) {
      navigate("/");
    }
  }, [session]);

  return (
    <Box sx={pageStyle}>
      <Header />
      <Box sx={wrapperStyle}>
        {upSm && <Sidebar />}
        <Content>
          <Outlet />
        </Content>
      </Box>
      <Footer />
    </Box>
  );
};

export default AuthLayout;

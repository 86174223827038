import { Auth } from "aws-amplify";

export const LOGIN_REQUEST = "@@session/LOGIN_REQUEST";
export const LOGIN_SUCCESS = "@@session/LOGIN_SUCCESS";
export const LOGIN_FAILURE = "@@session/LOGIN_FAILURE";

export function login(formData) {
  return async (dispatch) => {
    dispatch({
      type: LOGIN_REQUEST,
      error: {},
    });

    try {
      const response = await Auth.signIn(formData.email, formData.password);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: LOGIN_FAILURE,
        payload: error,
      });
    }
  };
}

export const CHANGE_PASSWORD_REQUEST = "@@session/CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "@@session/CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "@@session/CHANGE_PASSWORD_FAILURE";

export function changePassword(user, formData) {
  return async (dispatch) => {
    dispatch({
      type: CHANGE_PASSWORD_REQUEST,
    });

    try {
      const response = await Auth.changePassword(
        user,
        formData?.oldPassword,
        formData?.newPassword
      );
      dispatch({
        type: CHANGE_PASSWORD_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: CHANGE_PASSWORD_FAILURE,
        payload: error,
      });
    }
  };
}

export const UPDATE_DETAILS_REQUEST = "@@session/UPDATE_DETAILS_REQUEST";
export const UPDATE_DETAILS_SUCCESS = "@@session/UPDATE_DETAILS_SUCCESS";
export const UPDATE_DETAILS_FAILURE = "@@session/UPDATE_DETAILS_FAILURE";

export function updateDetails(user, password, data) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_DETAILS_REQUEST,
    });
    try {
      const response = await Auth.completeNewPassword(user, password, data);
      dispatch({
        type: UPDATE_DETAILS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_DETAILS_FAILURE,
        payload: error,
      });
    }
  };
}

export const CHECK_SESSION_REQUEST = "@@session/CHECK_SESSION_REQUEST";
export const CHECK_SESSION_SUCCESS = "@@session/CHECK_SESSION_SUCCESS";
export const CHECK_SESSION_FAILURE = "@@session/CHECK_SESSION_FAILURE";

export function checkSession() {
  return async (dispatch) => {
    dispatch({
      type: CHECK_SESSION_REQUEST,
    });

    try {
      const response = await Auth.currentAuthenticatedUser();
      const currentUnixTime = Math.round(new Date().getTime() / 1000);
      const exp = response?.signInUserSession?.idToken?.payload?.exp;
      if (exp - currentUnixTime >= 0) {
        dispatch({
          type: CHECK_SESSION_SUCCESS,
          payload: response,
        });
      } else {
        dispatch({
          type: CHECK_SESSION_FAILURE,
        });
      }
    } catch (error) {
      dispatch({
        type: CHECK_SESSION_FAILURE,
      });
    }
  };
}

export const LOGOUT_REQUEST = "@@session/LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "@@session/LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "@@session/LOGOUT_FAILURE";

export function logout() {
  return async (dispatch) => {
    dispatch({
      type: LOGOUT_REQUEST,
    });
    try {
      await Auth.signOut();
      dispatch({
        type: LOGOUT_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: LOGOUT_FAILURE,
        payload: error,
      });
    }
  };
}

export const SESSION_REFRESH_REQUEST = "@@session/SESSION_REFRESH_REQUEST";
export const SESSION_REFRESH_SUCCESS = "@@session/SESSION_REFRESH_SUCCESS";
export const SESSION_REFRESH_FAILURE = "@@session/SESSION_REFRESH_FAILURE";

export function sessionRefresh() {
  return async (dispatch) => {
    dispatch({
      type: SESSION_REFRESH_REQUEST,
    });
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      cognitoUser.refreshSession(
        currentSession.refreshToken,
        (err, session) => {
          dispatch({
            type: SESSION_REFRESH_SUCCESS,
            payload: session,
          });
        }
      );
    } catch (error) {
      dispatch({
        type: SESSION_REFRESH_FAILURE,
      });
    }
  };
}

export const FORGOT_PASSWORD_REQUEST = "@@session/FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "@@session/FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "@@session/FORGOT_PASSWORD_FAILURE";

export function forgotPassword(username) {
  return async (dispatch) => {
    dispatch({
      type: FORGOT_PASSWORD_REQUEST,
    });
    try {
      const response = await Auth.forgotPassword(username);
      dispatch({
        type: FORGOT_PASSWORD_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: FORGOT_PASSWORD_FAILURE,
        payload: error,
      });
    }
  };
}

export const FORGOT_PASSWORD_SUBMIT_REQUEST =
  "@@session/FORGOT_PASSWORD_SUBMIT_REQUEST";
export const FORGOT_PASSWORD_SUBMIT_SUCCESS =
  "@@session/FORGOT_PASSWORD_SUBMIT_SUCCESS";
export const FORGOT_PASSWORD_SUBMIT_FAILURE =
  "@@session/FORGOT_PASSWORD_SUBMIT_FAILURE";

export function forgotPasswordSubmit(formData) {
  return async (dispatch) => {
    dispatch({
      type: FORGOT_PASSWORD_SUBMIT_REQUEST,
    });
    try {
      const response = await Auth.forgotPasswordSubmit(
        formData.email,
        formData.code,
        formData.password
      );
      dispatch({
        type: FORGOT_PASSWORD_SUBMIT_SUCCESS,
        payload: response,
      });
      dispatch(
        login({
          email: formData.email,
          password: formData.password,
        })
      );
    } catch (error) {
      dispatch({
        type: FORGOT_PASSWORD_SUBMIT_FAILURE,
        payload: error,
      });
    }
  };
}

export const RESET_FORGOT_PASSWORD_FLAG =
  "@@session/RESET_FORGOT_PASSWORD_FLAG";

export function resetForgotPasswordFlag() {
  return async (dispatch) => {
    dispatch({
      type: RESET_FORGOT_PASSWORD_FLAG,
    });
  };
}

export const CLEAR_SESSION_ERROR = "@@session/CLEAR_SESSION_ERROR";
export function clearSessionError() {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_SESSION_ERROR,
    });
  };
}

//MFA setup
export const SETUP_MFA_REQUEST = "@@session/SETUP_MFA_REQUEST";
export const SETUP_MFA_SUCCESS = "@@session/SETUP_MFA_SUCCESS";
export const SETUP_MFA_FAILURE = "@@session/SETUP_MFA_FAILURE";

export function setupMFA(callback) {
  return async (dispatch) => {
    dispatch({
      type: SETUP_MFA_REQUEST,
    });
    try {
      const user = await Auth.currentAuthenticatedUser();
      const response = await Auth.setupTOTP(user);
      dispatch({
        type: SETUP_MFA_SUCCESS,
        payload: response,
      });
    } catch (error) {
      if (callback) {
        callback(false);
      }
      dispatch({
        type: SETUP_MFA_FAILURE,
        payload: error,
      });
    }
  };
}

export const VERIFY_MFA_REQUEST = "@@session/VERIFY_MFA_REQUEST";
export const VERIFY_MFA_SUCCESS = "@@session/VERIFY_MFA_SUCCESS";
export const VERIFY_MFA_FAILURE = "@@session/VERIFY_MFA_FAILURE";

export function verifyMFA(token, callback) {
  return async (dispatch) => {
    dispatch({
      type: VERIFY_MFA_REQUEST,
    });
    try {
      const user = await Auth.currentAuthenticatedUser();
      const response = await Auth.verifyTotpToken(user, token);
      Auth.setPreferredMFA(user, "TOTP");
      dispatch({
        type: VERIFY_MFA_SUCCESS,
        payload: response,
      });
      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch({
        type: VERIFY_MFA_FAILURE,
        payload: error,
      });
    }
  };
}

export const CONFIRM_SIGNIN_REQUEST = "@@session/CONFIRM_SIGNIN_REQUEST";
export const CONFIRM_SIGNIN_SUCCESS = "@@session/CONFIRM_SIGNIN_SUCCESS";
export const CONFIRM_SIGNIN_FAILURE = "@@session/CONFIRM_SIGNIN_FAILURE";

export function confirmSignIn(user, token) {
  return async (dispatch) => {
    dispatch({
      type: CONFIRM_SIGNIN_REQUEST,
    });
    try {
      const response = await Auth.confirmSignIn(user, token, "SMS_MFA"); //SOFTWARE_TOKEN_MFA in future if require TOTP
      dispatch({
        type: CONFIRM_SIGNIN_SUCCESS,
        payload: response,
      });

      dispatch(checkSession());
    } catch (error) {
      dispatch({
        type: CONFIRM_SIGNIN_FAILURE,
        payload: error,
      });
    }
  };
}

export function setPreferredMFA() {
  return async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      Auth.setPreferredMFA(user, "NOMFA");
    } catch (e) {
      //
    }
  };
}

import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const style = (theme) => {
  return {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItmes: "center",
    justifyContent: "center",
    padding: "12px",
  };
};

const Footer = () => {
  const [year, setYear] = useState();

  useEffect(() => {
    const year = new Date().getFullYear();
    setYear(year);
  }, []);

  return (
    <Box sx={(theme) => style(theme)}>
      <Typography className="copyright" variant="caption" color="white">
        © Copyright {year} Empower. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;

import { API } from "aws-amplify";
import { toast } from "react-toastify";

export const GET_STATUS_LIST_REQUEST = "@@dashboard/GET_STATUS_LIST_REQUEST";
export const GET_STATUS_LIST_SUCCESS = "@@dashboard/GET_STATUS_LIST_SUCCESS";
export const GET_STATUS_LIST_FAILURE = "@@dashboard/GET_STATUS_LIST_FAILURE";

export function getStatusList() {
  return async (dispatch) => {
    dispatch({
      type: GET_STATUS_LIST_REQUEST,
    });
    try {
      const response = await API.get("cmsAPI", "/status?state=installed");
      dispatch({
        type: GET_STATUS_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_STATUS_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_WAITING_LIST_REQUEST = "@@dashboard/GET_WAITING_LIST_REQUEST";
export const GET_WAITING_LIST_SUCCESS = "@@dashboard/GET_WAITING_LIST_SUCCESS";
export const GET_WAITING_LIST_FAILURE = "@@dashboard/GET_WAITING_LIST_FAILURE";

export function getWaitingList() {
  return async (dispatch) => {
    dispatch({
      type: GET_WAITING_LIST_REQUEST,
    });
    try {
      const response = await API.get("cmsAPI", "/waiting");
      dispatch({
        type: GET_WAITING_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_WAITING_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_COMMISSIONED_LIST_REQUEST =
  "@@dashboard/GET_COMMISSIONED_LIST_REQUEST";
export const GET_COMMISSIONED_LIST_SUCCESS =
  "@@dashboard/GET_COMMISSIONED_LIST_SUCCESS";
export const GET_COMMISSIONED_LIST_FAILURE =
  "@@dashboard/GET_COMMISSIONED_LIST_FAILURE";

export function getCommissionedList() {
  return async (dispatch) => {
    dispatch({
      type: GET_COMMISSIONED_LIST_REQUEST,
    });
    try {
      const response = await API.get(
        "cmsAPI",
        "/status?filter__state=operational"
      );
      dispatch({
        type: GET_COMMISSIONED_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_COMMISSIONED_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export const CONNECT_UNIT_REQUEST = "@@dashboard/CONNECT_UNIT_REQUEST";
export const CONNECT_UNIT_SUCCESS = "@@dashboard/CONNECT_UNIT_SUCCESS";
export const CONNECT_UNIT_FAILURE = "@@dashboard/CONNECT_UNIT_FAILURE";

export function connectUnit(id, callback) {
  return async (dispatch) => {
    if (!id) return;
    dispatch({
      type: CONNECT_UNIT_REQUEST,
      payload: {
        id,
      },
    });
    try {
      const response = await API.put("cmsAPI", `/unit/${id}/connect`);

      dispatch({
        type: CONNECT_UNIT_SUCCESS,
        payload: {
          ...response,
          id,
        },
      });
      if (callback) {
        callback(response);
      }
    } catch (error) {
      dispatch({
        type: CONNECT_UNIT_FAILURE,
        payload: {
          id,
          error,
        },
      });
    }
  };
}

export const UPGRADE_UNIT_REQUEST = "@@dashboard/UPGRADE_UNIT_REQUEST";
export const UPGRADE_UNIT_SUCCESS = "@@dashboard/UPGRADE_UNIT_SUCCESS";
export const UPGRADE_UNIT_FAILURE = "@@dashboard/UPGRADE_UNIT_FAILURE";

export function upgradeUnit(id) {
  return async (dispatch) => {
    if (!id) return;
    dispatch({
      type: UPGRADE_UNIT_REQUEST,
      payload: {
        id,
      },
    });
    try {
      const response = await API.put("cmsAPI", `/unit/${id}/upgrade`);
      dispatch({
        type: UPGRADE_UNIT_SUCCESS,
        payload: {
          ...response,
          id,
        },
      });
    } catch (error) {
      dispatch({
        type: UPGRADE_UNIT_FAILURE,
        payload: {
          id,
          error,
        },
      });
      dispatch(connectUnit(id));
    }
  };
}

export const GET_UPGRADE_UNIT_REQUEST = "@@dashboard/GET_UPGRADE_UNIT_REQUEST";
export const GET_UPGRADE_UNIT_SUCCESS = "@@dashboard/GET_UPGRADE_UNIT_SUCCESS";
export const GET_UPGRADE_UNIT_FAILURE = "@@dashboard/GET_UPGRADE_UNIT_FAILURE";

export function getUpgradeUnit(id) {
  return async (dispatch) => {
    if (!id) return;
    dispatch({
      type: GET_UPGRADE_UNIT_REQUEST,
      payload: {
        id,
      },
    });
    try {
      const response = await API.get("cmsAPI", `/unit/${id}/upgrade`);
      dispatch({
        type: GET_UPGRADE_UNIT_SUCCESS,
        payload: {
          ...response,
          id,
        },
      });
      if (!response?.upgrading) {
        dispatch(connectUnit(id));
      }
    } catch (error) {
      dispatch({
        type: GET_UPGRADE_UNIT_FAILURE,
        payload: {
          id,
          error,
        },
      });
      dispatch(connectUnit(id));
    }
  };
}

export const INITIALISE_BATTERY_UNIT_REQUEST =
  "@@dashboard/INITIALISE_BATTERY_UNIT_REQUEST";
export const INITIALISE_BATTERY_UNIT_SUCCESS =
  "@@dashboard/INITIALISE_BATTERY_UNIT_SUCCESS";
export const INITIALISE_BATTERY_UNIT_FAILURE =
  "@@dashboard/INITIALISE_BATTERY_UNIT_FAILURE";

export function initializeBatteryUnit(id) {
  return async (dispatch) => {
    if (!id) return;
    dispatch({
      type: INITIALISE_BATTERY_UNIT_REQUEST,
      payload: {
        id,
      },
    });
    try {
      const response = await API.put("cmsAPI", `/unit/${id}/battery`);
      dispatch({
        type: INITIALISE_BATTERY_UNIT_SUCCESS,
        payload: {
          ...response,
          id,
        },
      });
    } catch (error) {
      dispatch({
        type: INITIALISE_BATTERY_UNIT_FAILURE,
        payload: {
          id,
          error,
        },
      });
    }
  };
}

export const ASSIGN_UNIT_REQUEST = "@@dashboard/ASSIGN_UNIT_REQUEST";
export const ASSIGN_UNIT_SUCCESS = "@@dashboard/ASSIGN_UNIT_SUCCESS";
export const ASSIGN_UNIT_FAILURE = "@@dashboard/ASSIGN_UNIT_FAILURE";

export function assignUnit(formData, callback) {
  return async (dispatch) => {
    dispatch({
      type: ASSIGN_UNIT_REQUEST,
    });
    try {
      const init = {
        body: formData,
      };
      const response = await API.post("cmsAPI", `/assign`, init);
      dispatch({
        type: ASSIGN_UNIT_SUCCESS,
        payload: response,
      });
      if (callback) {
        callback();
      }
      dispatch(getWaitingList());
    } catch (error) {
      toast.error(error?.response?.data?.message);
      if (error?.response?.data?.serial?.length > 0) {
        error?.response?.data.serial.forEach((it) => toast.error(it));
      }
      dispatch({
        type: ASSIGN_UNIT_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_NETWORK_ACCESS_LIST_REQUEST =
  "@@dashboard/GET_NETWORK_ACCESS_LIST_REQUEST";
export const GET_NETWORK_ACCESS_LIST_SUCCESS =
  "@@dashboard/GET_NETWORK_ACCESS_LIST_SUCCESS";
export const GET_NETWORK_ACCESS_LIST_FAILURE =
  "@@dashboard/GET_NETWORK_ACCESS_LIST_FAILURE";

export function getNetworkAccessList(id) {
  if (!id) return;
  return async (dispatch) => {
    dispatch({
      type: GET_NETWORK_ACCESS_LIST_REQUEST,
    });
    try {
      const response = await API.get("cmsAPI", `/unit/${id}/aplist`);
      dispatch({
        type: GET_NETWORK_ACCESS_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_NETWORK_ACCESS_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_NETWORK_SETTINGS_REQUEST =
  "@@dashboard/GET_NETWORK_SETTINGS_REQUEST";
export const GET_NETWORK_SETTINGS_SUCCESS =
  "@@dashboard/GET_NETWORK_SETTINGS_SUCCESS";
export const GET_NETWORK_SETTINGS_FAILURE =
  "@@dashboard/GET_NETWORK_SETTINGS_FAILURE";

export function getNetworkSettings(id) {
  if (!id) return;
  return async (dispatch) => {
    dispatch({
      type: GET_NETWORK_SETTINGS_REQUEST,
    });
    try {
      const response = await API.get("cmsAPI", `/unit/${id}/network`);
      dispatch({
        type: GET_NETWORK_SETTINGS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_NETWORK_SETTINGS_FAILURE,
        payload: error,
      });
    }
  };
}

export const PUT_NETWORK_SETTINGS_REQUEST =
  "@@dashboard/PUT_NETWORK_SETTINGS_REQUEST";
export const PUT_NETWORK_SETTINGS_SUCCESS =
  "@@dashboard/PUT_NETWORK_SETTINGS_SUCCESS";
export const PUT_NETWORK_SETTINGS_FAILURE =
  "@@dashboard/PUT_NETWORK_SETTINGS_FAILURE";

export function updateNetworkSettings(id, data, callback) {
  if (!id) return;
  return async (dispatch) => {
    await dispatch(setCounter(1));
    dispatch({
      type: PUT_NETWORK_SETTINGS_REQUEST,
    });
    try {
      const init = {
        body: {
          settings: data,
        },
      };
      const response = await API.put("cmsAPI", `/unit/${id}/network`, init);
      dispatch({
        type: PUT_NETWORK_SETTINGS_SUCCESS,
        payload: response,
      });

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch({
        type: PUT_NETWORK_SETTINGS_FAILURE,
        payload: error,
      });
    }
  };
}

export const CLEAR_PREVIOUS_NETWORK_SETTINGS =
  "@@dashboard/CLEAR_PREVIOUS_NETWORK_SETTINGS";

export function clearPreviousNetworkSettings() {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_PREVIOUS_NETWORK_SETTINGS,
    });
  };
}

export const SET_COUNTER = "@@dashboard/SET_COUNTER";

export function setCounter(value) {
  return async (dispatch) => {
    dispatch({
      type: SET_COUNTER,
      payload: value,
    });
  };
}

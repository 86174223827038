import { API } from "aws-amplify";
import { clearVerificationDetails } from "./verification";

export const GET_UNIT_DETAILS_REQUEST = "@@unit/GET_UNIT_DETAILS_REQUEST";
export const GET_UNIT_DETAILS_SUCCESS = "@@unit/GET_UNIT_DETAILS_SUCCESS";
export const GET_UNIT_DETAILS_FAILURE = "@@unit/GET_UNIT_DETAILS_FAILURE";

export function getUnitDetails(id) {
  return async (dispatch) => {
    if (!id) return;
    dispatch({
      type: GET_UNIT_DETAILS_REQUEST,
    });
    try {
      const response = await API.get("cmsAPI", `/unit/${id}`);
      dispatch({
        type: GET_UNIT_DETAILS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_UNIT_DETAILS_FAILURE,
        payload: error,
      });
    }
  };
}

export const PUT_UNIT_REQUEST = "@@unit/PUT_UNIT_REQUEST";
export const PUT_UNIT_SUCCESS = "@@unit/PUT_UNIT_SUCCESS";
export const PUT_UNIT_FAILURE = "@@unit/PUT_UNIT_FAILURE";

export function updateUnit(id, value, callback) {
  return async (dispatch, getState) => {
    if (!id) return;
    dispatch({
      type: PUT_UNIT_REQUEST,
    });
    try {
      const body = {
        body: value,
      };
      const response = await API.put("cmsAPI", `/unit/${id}`, body);
      dispatch({
        type: PUT_UNIT_SUCCESS,
        payload: response,
      });
      const { verification } = getState();
      if (verification?.stepData.length > 0) {
        dispatch(clearVerificationDetails());
      }
      dispatch(getUnitDetails(id));
      if (callback) {
        callback(true);
      }
    } catch (error) {
      dispatch({
        type: PUT_UNIT_FAILURE,
        payload: error,
      });
      if (callback) {
        callback(false);
      }
    }
  };
}

export const CLEAR_UNIT_DETAILS = "@@unit/CLEAR_UNIT_DETAILS";

export function clearUnitDetails() {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_UNIT_DETAILS,
    });
  };
}

export const GET_UNIT_STATUS_REQUEST = "@@unit/GET_UNIT_STATUS_REQUEST";
export const GET_UNIT_STATUS_SUCCESS = "@@unit/GET_UNIT_STATUS_SUCCESS";
export const GET_UNIT_STATUS_FAILURE = "@@unit/GET_UNIT_STATUS_FAILURE";

export function getUnitStatus(id) {
  return async (dispatch) => {
    if (!id) return;
    dispatch({
      type: GET_UNIT_STATUS_REQUEST,
    });
    try {
      const response = await API.get("cmsAPI", `/unit/${id}/status`);
      dispatch({
        type: GET_UNIT_STATUS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_UNIT_STATUS_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_UNIT_EVENTS_REQUEST = "@@unit/GET_UNIT_EVENTS_REQUEST";
export const GET_UNIT_EVENTS_SUCCESS = "@@unit/GET_UNIT_EVENTS_SUCCESS";
export const GET_UNIT_EVENTS_FAILURE = "@@unit/GET_UNIT_EVENTS_FAILURE";

export function getUnitEvents(id) {
  return async (dispatch) => {
    if (!id) return;
    dispatch({
      type: GET_UNIT_EVENTS_REQUEST,
    });
    try {
      const response = await API.get("cmsAPI", `/unit/${id}/event`);
      dispatch({
        type: GET_UNIT_EVENTS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_UNIT_EVENTS_FAILURE,
        payload: error,
      });
    }
  };
}

export const CLEAR_UNIT_EVENTS_REQUEST = "@@unit/CLEAR_UNIT_EVENTS_REQUEST";
export const CLEAR_UNIT_EVENTS_SUCCESS = "@@unit/CLEAR_UNIT_EVENTS_SUCCESS";
export const CLEAR_UNIT_EVENTS_FAILURE = "@@unit/CLEAR_UNIT_EVENTS_FAILURE";

export function clearUnitEvents(id) {
  return async (dispatch) => {
    if (!id) return;
    dispatch({
      type: CLEAR_UNIT_EVENTS_REQUEST,
    });
    try {
      const body = {
        body: {
          command: "clear_halt",
        },
      };

      const response = await API.post("cmsAPI", `/unit/${id}/command`, body);
      dispatch({
        type: CLEAR_UNIT_EVENTS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: CLEAR_UNIT_EVENTS_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_OWNER_STATUS_REQUEST = "@@unit/GET_OWNER_STATUS_REQUEST";
export const GET_OWNER_STATUS_SUCCESS = "@@unit/GET_OWNER_STATUS_SUCCESS";
export const GET_OWNER_STATUS_FAILURE = "@@unit/GET_OWNER_STATUS_FAILURE";

export function getOwnerStatus(id) {
  return async (dispatch) => {
    if (!id) return;
    dispatch({
      type: GET_OWNER_STATUS_REQUEST,
    });
    try {
      const response = await API.get("cmsAPI", `/unit/${id}/owner`);
      dispatch({
        type: GET_OWNER_STATUS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_OWNER_STATUS_FAILURE,
        payload: error,
      });
    }
  };
}

export const PUT_OWNER_STATUS_REQUEST = "@@unit/PUT_OWNER_STATUS_REQUEST";
export const PUT_OWNER_STATUS_SUCCESS = "@@unit/PUT_OWNER_STATUS_SUCCESS";
export const PUT_OWNER_STATUS_FAILURE = "@@unit/PUT_OWNER_STATUS_FAILURE";

export function updateOwnerStatus(id, value, callback) {
  return async (dispatch) => {
    if (!id) return;
    dispatch({
      type: PUT_OWNER_STATUS_REQUEST,
    });
    try {
      const body = {
        body: value,
      };

      const response = await API.put("cmsAPI", `/unit/${id}/owner`, body);
      dispatch({
        type: PUT_OWNER_STATUS_SUCCESS,
        payload: response,
      });
      dispatch(getUnitDetails(id));
      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch({
        type: PUT_OWNER_STATUS_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_UNIT_BATTERY_MODULE_REQUEST =
  "@@unit/GET_UNIT_BATTERY_MODULE_REQUEST";
export const GET_UNIT_BATTERY_MODULE_SUCCESS =
  "@@unit/GET_UNIT_BATTERY_MODULE_SUCCESS";
export const GET_UNIT_BATTERY_MODULE_FAILURE =
  "@@unit/GET_UNIT_BATTERY_MODULE_FAILURE";

export function getUnitBatteryModule(id) {
  return async (dispatch) => {
    if (!id) return;
    dispatch({
      type: GET_UNIT_BATTERY_MODULE_REQUEST,
    });
    try {
      const response = await API.get("cmsAPI", `/unit/${id}/battery_module`);
      dispatch({
        type: GET_UNIT_BATTERY_MODULE_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_UNIT_BATTERY_MODULE_FAILURE,
        payload: error,
      });
    }
  };
}

export const PUT_UNIT_BATTERY_MODULE_REQUEST =
  "@@unit/PUT_UNIT_BATTERY_MODULE_REQUEST";
export const PUT_UNIT_BATTERY_MODULE_SUCCESS =
  "@@unit/PUT_UNIT_BATTERY_MODULE_SUCCESS";
export const PUT_UNIT_BATTERY_MODULE_FAILURE =
  "@@unit/PUT_UNIT_BATTERY_MODULE_FAILURE";

export function updateUnitBatteryModule(id, value, callback) {
  return async (dispatch) => {
    if (!id) return;
    dispatch({
      type: PUT_UNIT_BATTERY_MODULE_REQUEST,
    });
    try {
      const body = {
        body: value,
      };
      const response = await API.put(
        "cmsAPI",
        `/unit/${id}/battery_module`,
        body
      );
      dispatch({
        type: PUT_UNIT_BATTERY_MODULE_SUCCESS,
        payload: response,
      });
      dispatch(getUnitBatteryModule(id));
      dispatch(getUnitDetails(id));
      if (callback) {
        callback(true);
      }
    } catch (error) {
      dispatch({
        type: PUT_UNIT_BATTERY_MODULE_FAILURE,
        payload: error,
      });
      if (callback) {
        callback(false);
      }
    }
  };
}

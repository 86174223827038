/* eslint-disable no-undef */
import { memo, useCallback, useEffect, useState } from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { Box, Typography } from "@mui/material";

const containerStyle = {
  width: "400px",
  height: "100%",
};

function Map({ details }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  // eslint-disable-next-line no-unused-vars
  const [map, setMap] = useState(null);

  const [locatedCenter, setLocatedCenter] = useState(false);
  const [activeMarker, setActiveMarker] = useState(null);

  useEffect(() => {
    if (details?.id && !locatedCenter) {
      setLocatedCenter(true);
    }
  }, [details]);

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds({
      lat: Number(details?.latitude),
      lng: Number(details.longitude),
    });
    map.fitBounds(bounds);
    map.setZoom(13);

    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  return isLoaded && locatedCenter ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{
        lat: Number(details?.latitude),
        lng: Number(details.longitude),
      }}
      onLoad={onLoad}
      onUnmount={onUnmount}
      zoom={13}
      options={{
        maxZoom: 16,
      }}
    >
      <Box>
        <Marker
          {...details}
          title={details.nmi}
          name={details.nmi}
          position={{
            lat: Number(details.latitude),
            lng: Number(details.longitude),
          }}
          onClick={() => setActiveMarker(details)}
        >
          {activeMarker?.id === details.id ? (
            <InfoWindow onCloseClick={() => setActiveMarker(null)}>
              <Box width="200px">
                <Typography>{details.nmi}</Typography>
              </Box>
            </InfoWindow>
          ) : null}
        </Marker>
      </Box>
    </GoogleMap>
  ) : (
    <></>
  );
}

export default memo(Map);

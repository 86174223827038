import { Box, Typography } from "@mui/material";

const styles = (value, total, error) => {
  const progressBarWidth = (value / total) * 100;
  return {
    display: "flex",
    alignItems: "center",
    "& .progress-bar": {
      width: "100%",
      height: "4px",
      marginTop: "4px",
      backgroundColor: "#C9C9C9",
      position: "relative",
    },
    "& .actual-bar": {
      position: "absolute",
      backgroundColor: error ? "#91201B" : "#004057",
      height: "4px",
      width: `${progressBarWidth}%`,
    },
  };
};

const Progress = ({ label, value, total, unit, error = false }) => {

  value = Math.round(value * 100) / 100
  total = Math.round(total * 100) / 100
  return (
    <Box sx={() => styles(value, total, error)}>
      <Box flexGrow={1} mr={1}>
        {label && (
          <Typography variant="subtitle1" color={error ? "#91201B" : "#004057"}>
            {label}
          </Typography>
        )}
        <Box className="progress-bar">
          <Box className="actual-bar"></Box>
        </Box>
      </Box>
      <Typography variant="subtitle1">{`${value} / ${total} ${unit}`}</Typography>
    </Box>
  );
};

export default Progress;

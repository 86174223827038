import { useCallback, useEffect, useRef, useState } from "react";
import { BarcodeReader, StrichSDK } from "@pixelverse/strichjs-sdk";
import { Box } from "@mui/material";

const styles = () => {
  return {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    "& .barcodeReader": {
      height: "240px",
      position: "relative",
      backgroundColor: "black",
    },
  };
};

function createBarcodeReaderConfig(hostElem) {
  return {
    selector: hostElem,
    engine: {
      symbologies: ["code128", "qr"],
      duplicateInterval: 2500,
      numScanlines: 20,
      minScanlinesNeeded: 3,
    },
    locator: {
      regionOfInterest: {
        left: 0.05,
        right: 0.05,
        top: 0.3,
        bottom: 0.3,
      },
    },
    frameSource: {
      resolution: "full-hd",
    },
    feedback: {
      audio: true,
      vibration: true,
    },
  };
}

function ScannerHost({ addDetection }) {
  const hostElemRef = useRef(null);

  const [sdkState, setSdkState] = useState(
    StrichSDK.isInitialized() ? "initialized" : undefined
  );

  const barcodeReaderRef = useRef(null);

  useEffect(() => {
    const initializeSDK = async () => {
      if (StrichSDK.isInitialized()) {
        setSdkState("initialized");
      } else {
        try {
          // eslint-disable-next-line no-undef
          await StrichSDK.initialize(process.env.REACT_APP_STRICH_SDK_KEY);
          console.log(`STRICH SDK initialized successfully`);
          setSdkState("initialized");
        } catch (e) {
          console.error(`Failed to initialize STRICH SDK: ${e}`);
          setSdkState("initialization-error");
        }
      }
    };

    if (sdkState === undefined) {
      setSdkState("initializing");
      initializeSDK();
    }
  }, []);

  useEffect(() => {
    if (sdkState === "initialized" && barcodeReaderRef.current === null) {
      const barcodeReaderInitialization = async () => {
        console.log(`Initializing BarcodeReader...`);

        const barcodeReader = new BarcodeReader(
          createBarcodeReaderConfig(hostElemRef.current)
        );
        barcodeReaderRef.current = barcodeReader;
        await barcodeReader.initialize();

        barcodeReader.detected = (detections) => {
          detections.map((d) => addDetection(d));
        };
        await barcodeReader.start();
      };
      barcodeReaderInitialization();

      return () => {
        if (barcodeReaderRef.current !== null) {
          console.log(`Destroying BarcodeReader`);
          barcodeReaderRef.current.destroy();
          barcodeReaderRef.current = null;
        }
      };
    }
  }, [sdkState]);

  return <div className="barcodeReader" ref={hostElemRef} />;
}

const BarcodeScanner = ({ onChange }) => {
  const [detection, setDetection] = useState("");

  const addDetection = useCallback(
    (detect) => {
      setDetection(detect);
    },
    [setDetection]
  );

  useEffect(() => {
    if (detection?.data) {
      onChange(detection.data);
    }
  }, [detection]);

  return (
    <Box sx={styles}>
      <ScannerHost addDetection={addDetection} />
    </Box>
  );
};

export default BarcodeScanner;

import { API } from "aws-amplify";
import { VERIFICATION_STATUS } from "constants/wizard";
import { VERIFICATION_STEP } from "constants/wizard";

export const GET_VERIFICATION_STEP_DATA_REQUEST =
  "@@verification/GET_VERIFICATION_STEP_DATA_REQUEST";
export const GET_VERIFICATION_STEP_DATA_SUCCESS =
  "@@verification/GET_VERIFICATION_STEP_DATA_SUCCESS";
export const GET_VERIFICATION_STEP_DATA_FAILURE =
  "@@verification/GET_VERIFICATION_STEP_DATA_FAILURE";

export function getAllVerificationStepData(id, callback) {
  return async (dispatch) => {
    dispatch({
      type: GET_VERIFICATION_STEP_DATA_REQUEST,
    });
    try {
      const response = await API.get("cmsAPI", `/unit/${id}/verification`);
      dispatch({
        type: GET_VERIFICATION_STEP_DATA_SUCCESS,
        payload: response,
      });
    } catch (error) {
      if (callback) {
        callback();
      }
      dispatch({
        type: GET_VERIFICATION_STEP_DATA_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_STEP_DATA_REQUEST = "@@verification/GET_STEP_DATA_REQUEST";
export const GET_STEP_DATA_SUCCESS = "@@verification/GET_STEP_DATA_SUCCESS";
export const GET_STEP_DATA_FAILURE = "@@verification/GET_STEP_DATA_FAILURE";

export function getStepData(id, step) {
  return async (dispatch) => {
    dispatch({
      type: GET_STEP_DATA_REQUEST,
    });
    try {
      const response = await API.get(
        "cmsAPI",
        `/unit/${id}/verification/${step}`
      );
      dispatch({
        type: GET_STEP_DATA_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_STEP_DATA_FAILURE,
        payload: error,
      });
    }
  };
}

export const POST_VERIFICATION_STEP_DATA_REQUEST =
  "@@verification/POST_VERIFICATION_STEP_DATA_REQUEST";
export const POST_VERIFICATION_STEP_DATA_SUCCESS =
  "@@verification/POST_VERIFICATION_STEP_DATA_SUCCESS";
export const POST_VERIFICATION_STEP_DATA_FAILURE =
  "@@verification/POST_VERIFICATION_STEP_DATA_FAILURE";

export function createVerificationStepData(id) {
  return async (dispatch, getState) => {
    const { verification } = getState();
    if (verification?.createLoading) return;
    dispatch({
      type: POST_VERIFICATION_STEP_DATA_REQUEST,
    });
    try {
      const body = {
        body: {},
      };
      const response = await API.post(
        "cmsAPI",
        `/unit/${id}/verification`,
        body
      );
      dispatch({
        type: POST_VERIFICATION_STEP_DATA_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: POST_VERIFICATION_STEP_DATA_FAILURE,
        payload: error,
      });
    }
  };
}

export const CHECK_PRECONDITIONS_REQUEST =
  "@@verification/CHECK_PRECONDITIONS_REQUEST";
export const CHECK_PRECONDITIONS_SUCCESS =
  "@@verification/CHECK_PRECONDITIONS_SUCCESS";
export const CHECK_PRECONDITIONS_FAILURE =
  "@@verification/CHECK_PRECONDITIONS_FAILURE";

export function checkPreconditions(id, step = "initial_verification") {
  return async (dispatch) => {
    dispatch({
      type: CHECK_PRECONDITIONS_REQUEST,
    });
    try {
      const body = {
        body: {},
      };
      const response = await API.put(
        "cmsAPI",
        `/unit/${id}/verification/${step}/check_preconditions`,
        body
      );
      dispatch({
        type: CHECK_PRECONDITIONS_SUCCESS,
        payload: response,
      });
      // dispatch(getStepData(id, step));
    } catch (error) {
      dispatch({
        type: CHECK_PRECONDITIONS_FAILURE,
        payload: error,
      });
    }
  };
}

export const START_AUTO_TEST_REQUEST = "@@verification/START_AUTO_TEST_REQUEST";
export const START_AUTO_TEST_SUCCESS = "@@verification/START_AUTO_TEST_SUCCESS";
export const START_AUTO_TEST_FAILURE = "@@verification/START_AUTO_TEST_FAILURE";

export function startAutoTest(id, step = "initial_verification") {
  return async (dispatch) => {
    dispatch({
      type: START_AUTO_TEST_REQUEST,
    });
    try {
      const body = {
        body: {},
      };
      const response = await API.put(
        "cmsAPI",
        `/unit/${id}/verification/${step}/start_auto_test`,
        body
      );
      dispatch({
        type: START_AUTO_TEST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: START_AUTO_TEST_FAILURE,
        payload: error,
      });
    }
  };
}

export const REINITIALISE_TEST_REQUEST =
  "@@verification/REINITIALISE_TEST_REQUEST";
export const REINITIALISE_TEST_SUCCESS =
  "@@verification/REINITIALISE_TEST_SUCCESS";
export const REINITIALISE_TEST_FAILURE =
  "@@verification/REINITIALISE_TEST_FAILURE";

export function reinitialiseTest(id, step = "initial_verification") {
  return async (dispatch) => {
    dispatch({
      type: REINITIALISE_TEST_REQUEST,
    });
    try {
      const body = {
        body: {},
      };
      const response = await API.put(
        "cmsAPI",
        `/unit/${id}/verification/${step}/reinitialise`,
        body
      );
      dispatch({
        type: REINITIALISE_TEST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: REINITIALISE_TEST_FAILURE,
        payload: error,
      });
    }
  };
}

export const REFRESH_DATA_REQUEST = "@@verification/REFRESH_DATA_REQUEST";
export const REFRESH_DATA_SUCCESS = "@@verification/REFRESH_DATA_SUCCESS";
export const REFRESH_DATA_FAILURE = "@@verification/REFRESH_DATA_FAILURE";

export function refreshData(id, step = "meter_verification", hold = false) {
  return async (dispatch) => {
    dispatch({
      type: REFRESH_DATA_REQUEST,
    });
    try {
      const body = {
        body: {},
      };
      const response = await API.put(
        "cmsAPI",
        `/unit/${id}/verification/${step}/refresh_data${
          hold ? "?hold_values=true" : ""
        }`,
        body
      );
      dispatch({
        type: REFRESH_DATA_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: REFRESH_DATA_FAILURE,
        payload: error,
      });
    }
  };
}

export const UPDATE_CHECKLIST_REQUEST =
  "@@verification/UPDATE_CHECKLIST_REQUEST";
export const UPDATE_CHECKLIST_SUCCESS =
  "@@verification/UPDATE_CHECKLIST_SUCCESS";
export const UPDATE_CHECKLIST_FAILURE =
  "@@verification/UPDATE_CHECKLIST_FAILURE";

export function updateChecklist(
  id,
  step = "meter_verification",
  checklist = [],
  callback
) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_CHECKLIST_REQUEST,
    });
    try {
      const body = {
        body: {
          progress: {
            checklist,
          },
        },
      };
      const response = await API.put(
        "cmsAPI",
        `/unit/${id}/verification/${step}/update_checklist`,
        body
      );

      if (callback) {
        callback();
      }

      dispatch({
        type: UPDATE_CHECKLIST_SUCCESS,
        payload: response,
      });

      if (
        response.step === VERIFICATION_STEP.METER_VERIFICATION &&
        response.status === VERIFICATION_STATUS.completed
      ) {
        dispatch(refreshData(id, step));
      }
    } catch (error) {
      dispatch({
        type: UPDATE_CHECKLIST_FAILURE,
        payload: error,
      });
    }
  };
}

export const POSTPONE_STEP_REQUEST = "@@verification/POSTPONE_STEP_REQUEST";
export const POSTPONE_STEP_SUCCESS = "@@verification/POSTPONE_STEP_SUCCESS";
export const POSTPONE_STEP_FAILURE = "@@verification/POSTPONE_STEP_FAILURE";

export function postponeStep(id, step = "pv_verification", callback) {
  return async (dispatch) => {
    dispatch({
      type: POSTPONE_STEP_REQUEST,
    });
    try {
      const body = {
        body: {},
      };
      const response = await API.put(
        "cmsAPI",
        `/unit/${id}/verification/${step}/postpone`,
        body
      );
      dispatch({
        type: POSTPONE_STEP_SUCCESS,
        payload: response,
      });
      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch({
        type: POSTPONE_STEP_FAILURE,
        payload: error,
      });
    }
  };
}

export const CLEAR_VERIFICATION_DETAILS =
  "@@verification/CLEAR_VERIFICATION_DETAILS";

export function clearVerificationDetails() {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_VERIFICATION_DETAILS,
    });
  };
}

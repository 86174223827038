import {
  GET_VERIFICATION_STEP_DATA_REQUEST,
  GET_VERIFICATION_STEP_DATA_SUCCESS,
  GET_VERIFICATION_STEP_DATA_FAILURE,
  POST_VERIFICATION_STEP_DATA_REQUEST,
  POST_VERIFICATION_STEP_DATA_SUCCESS,
  POST_VERIFICATION_STEP_DATA_FAILURE,
  GET_STEP_DATA_REQUEST,
  GET_STEP_DATA_SUCCESS,
  GET_STEP_DATA_FAILURE,
  CHECK_PRECONDITIONS_REQUEST,
  CHECK_PRECONDITIONS_SUCCESS,
  CHECK_PRECONDITIONS_FAILURE,
  START_AUTO_TEST_REQUEST,
  START_AUTO_TEST_SUCCESS,
  START_AUTO_TEST_FAILURE,
  REINITIALISE_TEST_REQUEST,
  REINITIALISE_TEST_SUCCESS,
  REINITIALISE_TEST_FAILURE,
  REFRESH_DATA_REQUEST,
  REFRESH_DATA_SUCCESS,
  REFRESH_DATA_FAILURE,
  UPDATE_CHECKLIST_REQUEST,
  UPDATE_CHECKLIST_SUCCESS,
  UPDATE_CHECKLIST_FAILURE,
  CLEAR_VERIFICATION_DETAILS,
} from "actions/verification";
import { cloneDeep } from "lodash";

const initialState = {
  loading: false,
  stepData: [],
  error: {},
  createLoading: false,
  stepLoading: false,
  refreshLoading: false,
};

export default function verification(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_VERIFICATION_STEP_DATA_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_VERIFICATION_STEP_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        stepData: payload,
      };
    }

    case GET_VERIFICATION_STEP_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case POST_VERIFICATION_STEP_DATA_REQUEST: {
      return {
        ...state,
        createLoading: true,
      };
    }

    case POST_VERIFICATION_STEP_DATA_SUCCESS: {
      return {
        ...state,
        createLoading: false,
        stepData: payload,
      };
    }

    case POST_VERIFICATION_STEP_DATA_FAILURE: {
      return {
        ...state,
        createLoading: false,
      };
    }

    case CHECK_PRECONDITIONS_REQUEST:
    case START_AUTO_TEST_REQUEST:
    case GET_STEP_DATA_REQUEST:
    case REINITIALISE_TEST_REQUEST:
    case UPDATE_CHECKLIST_REQUEST: {
      return {
        ...state,
        stepLoading: true,
      };
    }

    case REFRESH_DATA_REQUEST: {
      return {
        ...state,
        refreshLoading: true,
      };
    }

    case CHECK_PRECONDITIONS_SUCCESS:
    case START_AUTO_TEST_SUCCESS:
    case REINITIALISE_TEST_SUCCESS:
    case UPDATE_CHECKLIST_SUCCESS: {
      const stepData = cloneDeep(state.stepData);
      if (payload) {
        const index = stepData.findIndex((it) => it.step === payload.step);
        stepData[index] = payload;
      }

      return {
        ...state,
        stepLoading: false,
        stepData,
      };
    }

    case REFRESH_DATA_SUCCESS: {
      const stepData = cloneDeep(state.stepData);
      if (payload) {
        const index = stepData.findIndex((it) => it.step === payload.step);
        stepData[index] = payload;
      }

      return {
        ...state,
        refreshLoading: false,
        stepData,
      };
    }

    case GET_STEP_DATA_SUCCESS: {
      const stepData = cloneDeep(state.stepData);
      if (payload.length > 0) {
        const index = stepData.findIndex((it) => it.step === payload[0].step);
        stepData[index] = payload[0];
      }

      return {
        ...state,
        stepLoading: false,
        stepData,
      };
    }

    case CHECK_PRECONDITIONS_FAILURE:
    case START_AUTO_TEST_FAILURE:
    case GET_STEP_DATA_FAILURE:
    case REINITIALISE_TEST_FAILURE:
    case UPDATE_CHECKLIST_FAILURE: {
      return {
        ...state,
        stepLoading: false,
      };
    }

    case REFRESH_DATA_FAILURE: {
      return {
        ...state,
        refreshLoading: false,
      };
    }

    case CLEAR_VERIFICATION_DETAILS: {
      return {
        ...state,
        stepData: [],
      };
    }

    default:
      return state;
  }
}

import {
  SET_PAGE_TITLE,
  SET_WINDOW,
  GET_WINDOW,
  SET_FROM_TO_DATE,
  GET_FROM_TO_DATE,
} from "actions/page";

const initialState = {
  pageTitle: "",
  window: "day",
  fromToDate: {
    start: new Date(),
    end: new Date(),
  },
};

export default function page(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_PAGE_TITLE: {
      return {
        ...state,
        pageTitle: payload,
      };
    }

    case GET_WINDOW: {
      return {
        ...state,
      };
    }

    case SET_WINDOW: {
      return {
        ...state,
        window: payload,
      };
    }

    case GET_FROM_TO_DATE: {
      return {
        ...state,
      };
    }

    case SET_FROM_TO_DATE: {
      return {
        ...state,
        fromToDate: payload,
      };
    }

    default:
      return state;
  }
}

import { Modal } from "components";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { logout, confirmSignIn } from "actions/session";
import { LoadingButton } from "@mui/lab";
import OtpInput from "react-otp-input";

const styles = () => {
  return {
    minHeight: "200px",
    "& .loader": {
      display: "flex",
      alignItems: "center",
    },
    "& .mfa-section": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
  };
};

const MFAOtpModal = ({ open, onClose }) => {
  const dispatch = useDispatch();

  const [otp, setOtp] = useState("");

  const user = useSelector((state) => state.session?.user);
  const mfaVerifyLoading = useSelector(
    (state) => state.session?.mfaVerifyLoading
  );

  useEffect(() => {
    if (
      user?.constructor?.name !== "CognitoUser" &&
      user?.constructor?.name !== "e"
    ) {
      dispatch(logout());
    }
  }, [user]);

  const onClickVerify = () => {
    dispatch(confirmSignIn(user, otp));
  };

  return (
    <Modal
      width="400px"
      top="10%"
      title="Additional Security Verification"
      open={open}
      onClose={onClose}
      showHeaderBorder={true}
    >
      <Box mt={4} sx={styles}>
        <Box className="mfa-section">
          <Typography variant="h4">Enter One Time Passcode</Typography>
          <Typography variant="body2" my={1.5}>
            We have sent a one time passcode to your verified mobile number
          </Typography>
          <OtpInput
            inputStyle="otpInputStyle"
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<span></span>}
            renderInput={(props) => <input {...props} />}
            shouldAutoFocus
          />

          <LoadingButton
            sx={{ mt: 3 }}
            onClick={onClickVerify}
            variant="contained"
            loading={mfaVerifyLoading}
            disabled={otp.length === 0}
          >
            Verify OTP
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default MFAOtpModal;

import { Box, Typography } from "@mui/material";
import play from "images/dashboard/play.svg";
import battery from "images/dashboard/battery.svg";
import grid from "images/dashboard/grid.svg";
import solar from "images/dashboard/solar.svg";
import thermostat from "images/dashboard/thermostat.svg";
import storage from "images/dashboard/storage.svg";
import memory from "images/dashboard/memory.svg";

const getIcon = (type) => {
  switch (type) {
    case "operation":
      return play;

    case "grid":
      return grid;

    case "solar":
      return solar;

    case "battery":
      return battery;

    case "temp":
      return thermostat;

    case "memory":
      return memory;

    case "storage":
      return storage;
    default:
      return play;
  }
};

const UnitStatusIcon = ({ type, value1, value2 }) => {
  const Icon = getIcon(type);
  return (
    <Box
      className="items-center"
      mr={0.5}
      my={type === "operation" ? 0 : 1}
      sx={{ "&:last-child": { mr: 0 } }}
    >
      <Box className="items-center" sx={{ mr: "2px" }}>
        <img src={Icon} />
      </Box>
      <Box sx={{ flexDirection: "column" }} className="items-justify-center">
        <Typography fontSize="11px" lineHeight={1}>
          {value1}
        </Typography>
        <Typography fontSize="11px" lineHeight={1}>
          {value2}
        </Typography>
      </Box>
    </Box>
  );
};

export default UnitStatusIcon;

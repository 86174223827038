import { Box, Typography, Badge } from "@mui/material";
import { sidebarStyle } from "./styles";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { House } from "@mui/icons-material";

export const MENU_OPTIONS = [{ label: "Home", to: "/home", icon: <House /> }];

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [anyUnackowledge] = useState(false);

  const onClick = (item) => {
    navigate(item.to);
  };

  const checkActive = (item) => {
    let className = "";
    if (location.pathname.includes(item.to)) {
      className = "active";
    }
    return className;
  };

  return (
    <Box sx={(theme) => sidebarStyle(theme)}>
      {MENU_OPTIONS.map((item, i) => (
        <Box
          key={i}
          className={`sidebarItem ${checkActive(item)}`}
          onClick={() => onClick(item)}
        >
          {anyUnackowledge && item.label === "Notifications" ? (
            <Badge color="error" variant="dot" badgeContent=" ">
              <div>{item.icon}</div>
            </Badge>
          ) : (
            <div>{item.icon}</div>
          )}
          <Typography variant="caption" mt={-0.5}>
            {item.label}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default Sidebar;

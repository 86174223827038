import { useEffect, useState } from "react";
import { CssBaseline } from "@mui/material";
import { Suspense } from "react";
import Router from "./routes";
import ThemeConfig from "./theme";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import SessionCheck from "pages/Session/SessionCheck";
import { Loader } from "empower-energy-owner-components";
import { getProfile } from "actions/profile";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import { MFAOtpModal, MFASetupModal } from "components";
import axios from "axios";
import { getError } from "utils/errors";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status !== 401) {
      getError(error.response.data);
    }
    return Promise.reject(error);
  }
);

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [openMfaSetup, setOpenMfaSetup] = useState(false);
  const [openMfaOtpModal, setOpenMfaOtpModal] = useState(false);

  const error = useSelector((state) => state.session.error);
  const user = useSelector((state) => state.session.user);
  const session = useSelector((state) => state.session.session);

  useEffect(() => {
    if (error?.code === "PasswordResetRequiredException") {
      navigate("/reset-password");
    }
  }, [error]);

  useEffect(() => {
    if (user?.challengeName === "SMS_MFA") {
      setOpenMfaOtpModal(true);
    } else if (user?.challengeName === "MFA_SETUP") {
      setOpenMfaSetup(true);
    } else if (user?.challengeName === "SOFTWARE_TOKEN_MFA") {
      setOpenMfaOtpModal(true);
    } else if (user?.challengeName === "NEW_PASSWORD_REQUIRED") {
      navigate("/first-login");
    } else {
      setOpenMfaSetup(false);
      setOpenMfaOtpModal(false);
      if (
        session &&
        ["/", "/login", "/first-login", "/change-password"].includes(
          location.pathname
        )
      ) {
        navigate("/home");
      }
    }
  }, [user, session]);

  useEffect(() => {
    if (session) {
      dispatch(getProfile());
    }
  }, [session, dispatch]);

  return (
    <ThemeConfig>
      <CssBaseline />
      <SessionCheck />
      <Suspense fallback={<Loader show={true} fullPage={true} />}>
        <Router />
      </Suspense>
      <ToastContainer
        autoClose={3000}
        pauseOnFocusLoss={false}
        pauseOnHover={false}
      />
      {openMfaSetup && (
        <MFASetupModal
          open={openMfaSetup}
          onClose={() => setOpenMfaSetup(false)}
        />
      )}
      {openMfaOtpModal && (
        <MFAOtpModal
          open={openMfaOtpModal}
          onClose={() => setOpenMfaOtpModal(false)}
        />
      )}
    </ThemeConfig>
  );
}

export default App;

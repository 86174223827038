import {
  GET_STATUS_LIST_REQUEST,
  GET_STATUS_LIST_SUCCESS,
  GET_STATUS_LIST_FAILURE,
  GET_WAITING_LIST_REQUEST,
  GET_WAITING_LIST_SUCCESS,
  GET_WAITING_LIST_FAILURE,
  CONNECT_UNIT_REQUEST,
  CONNECT_UNIT_SUCCESS,
  CONNECT_UNIT_FAILURE,
  UPGRADE_UNIT_REQUEST,
  UPGRADE_UNIT_FAILURE,
  INITIALISE_BATTERY_UNIT_REQUEST,
  INITIALISE_BATTERY_UNIT_SUCCESS,
  INITIALISE_BATTERY_UNIT_FAILURE,
  ASSIGN_UNIT_REQUEST,
  ASSIGN_UNIT_SUCCESS,
  ASSIGN_UNIT_FAILURE,
  GET_NETWORK_ACCESS_LIST_REQUEST,
  GET_NETWORK_ACCESS_LIST_SUCCESS,
  GET_NETWORK_ACCESS_LIST_FAILURE,
  GET_NETWORK_SETTINGS_SUCCESS,
  GET_UPGRADE_UNIT_REQUEST,
  GET_UPGRADE_UNIT_FAILURE,
  GET_COMMISSIONED_LIST_REQUEST,
  GET_COMMISSIONED_LIST_SUCCESS,
  GET_COMMISSIONED_LIST_FAILURE,
  GET_NETWORK_SETTINGS_REQUEST,
  GET_NETWORK_SETTINGS_FAILURE,
  PUT_NETWORK_SETTINGS_REQUEST,
  PUT_NETWORK_SETTINGS_FAILURE,
  CLEAR_PREVIOUS_NETWORK_SETTINGS,
  SET_COUNTER,
} from "actions/dashboard";
import { cloneDeep } from "lodash";
import { toast } from "react-toastify";

const initialState = {
  statusLoading: false,
  statusList: [],
  waitingLoading: false,
  waitingList: [],
  commissionedLoading: false,
  commissionedList: [],
  error: {},
  connectLoading: [],
  addLoading: false,
  networkAccessList: [],
  networkAccessLoading: false,
  networkSettingsLoading: false,
  networkSettings: {},
  previousNetworkSettings: {},
  counter: 0,
};

export default function dashboard(state = initialState, action) {
  const { type, payload } = action;

  const getNetworkSettings = (settings) => {
    if (state.counter > 0 && state.counter < 3) {
      let s = cloneDeep(settings);
      s.status.updating = new Date().getTime();
      return s;
    } else {
      return settings;
    }
  };

  switch (type) {
    case GET_STATUS_LIST_REQUEST: {
      return {
        ...state,
        statusLoading: true,
      };
    }

    case GET_STATUS_LIST_SUCCESS: {
      return {
        ...state,
        statusLoading: false,
        statusList: payload,
      };
    }

    case GET_STATUS_LIST_FAILURE: {
      return {
        ...state,
        statusLoading: false,
      };
    }

    case GET_COMMISSIONED_LIST_REQUEST: {
      return {
        ...state,
        commissionedLoading: true,
      };
    }

    case GET_COMMISSIONED_LIST_SUCCESS: {
      return {
        ...state,
        commissionedLoading: false,
        commissionedList: payload,
      };
    }

    case GET_COMMISSIONED_LIST_FAILURE: {
      return {
        ...state,
        commissionedLoading: false,
      };
    }

    case GET_WAITING_LIST_REQUEST: {
      return {
        ...state,
        waitingLoading: true,
      };
    }

    case GET_WAITING_LIST_SUCCESS: {
      return {
        ...state,
        waitingLoading: false,
        waitingList: payload,
      };
    }

    case GET_WAITING_LIST_FAILURE: {
      return {
        ...state,
        waitingLoading: false,
      };
    }

    case CONNECT_UNIT_REQUEST:
    case INITIALISE_BATTERY_UNIT_REQUEST: {
      const connectLoading = cloneDeep(state.connectLoading);
      connectLoading.push(payload.id);

      return {
        ...state,
        connectLoading,
      };
    }

    case CONNECT_UNIT_SUCCESS: {
      const waitingList = cloneDeep(state.waitingList);
      const index = waitingList.findIndex((it) => it.id === payload.id);
      if (index > -1) {
        waitingList[index] = {
          ...waitingList[index],
          ...payload,
        };
      }
      const connectLoading = cloneDeep(state.connectLoading).filter(
        (it) => it !== payload.id
      );

      return {
        ...state,
        connectLoading,
        waitingList,
      };
    }

    case INITIALISE_BATTERY_UNIT_SUCCESS: {
      const waitingList = cloneDeep(state.waitingList);
      const index = waitingList.findIndex((it) => it.id === payload.id);
      if (index > -1) {
        waitingList[index].battery_initialised = payload.battery_initialised;
      }
      const connectLoading = cloneDeep(state.connectLoading).filter(
        (it) => it !== payload.id
      );

      return {
        ...state,
        connectLoading,
        waitingList,
      };
    }

    case CONNECT_UNIT_FAILURE:
    case INITIALISE_BATTERY_UNIT_FAILURE: {
      const connectLoading = cloneDeep(state.connectLoading).filter(
        (it) => it !== payload.id
      );
      toast.error(
        payload.error?.response?.data?.iot ||
          payload.error?.response?.data?.message
      );

      return {
        ...state,
        connectLoading,
      };
    }

    case ASSIGN_UNIT_REQUEST: {
      return {
        ...state,
        addLoading: true,
      };
    }

    case ASSIGN_UNIT_SUCCESS: {
      return {
        ...state,
        addLoading: false,
      };
    }

    case ASSIGN_UNIT_FAILURE: {
      return {
        ...state,
        addLoading: false,
      };
    }

    case GET_NETWORK_ACCESS_LIST_REQUEST: {
      return {
        ...state,
        networkAccessLoading: true,
      };
    }

    case GET_NETWORK_ACCESS_LIST_SUCCESS: {
      return {
        ...state,
        networkAccessLoading: false,
        networkAccessList: payload,
      };
    }

    case GET_NETWORK_ACCESS_LIST_FAILURE: {
      return {
        ...state,
        networkAccessLoading: false,
        networkAccessList: [],
      };
    }

    case GET_NETWORK_SETTINGS_REQUEST: {
      return {
        ...state,
        networkSettingsLoading: true,
      };
    }

    case PUT_NETWORK_SETTINGS_REQUEST: {
      return {
        ...state,
        networkSettingsLoading: true,
        previousNetworkSettings: state.networkSettings,
        counter: 1,
      };
    }

    case SET_COUNTER: {
      return {
        ...state,
        counter: payload,
      };
    }

    case GET_NETWORK_SETTINGS_FAILURE:
    case PUT_NETWORK_SETTINGS_FAILURE: {
      return {
        ...state,
        networkSettingsLoading: false,
      };
    }

    case GET_NETWORK_SETTINGS_SUCCESS: {
      return {
        ...state,
        networkSettings: getNetworkSettings(payload),
        networkSettingsLoading: false,
        counter: state.counter === 0 ? 0 : state.counter + 1,
      };
    }

    case UPGRADE_UNIT_REQUEST:
    case GET_UPGRADE_UNIT_REQUEST: {
      let waitingList = cloneDeep(state.waitingList);
      const index = waitingList.findIndex((it) => it.id === payload?.id);
      waitingList[index].upgrading = true;

      return {
        ...state,
        waitingList,
      };
    }

    case UPGRADE_UNIT_FAILURE:
    case GET_UPGRADE_UNIT_FAILURE: {
      toast.error(
        payload.error?.response?.data?.iot ||
          payload.error?.response?.data?.message
      );
      return {
        ...state,
      };
    }

    case CLEAR_PREVIOUS_NETWORK_SETTINGS: {
      return {
        ...state,
        previousNetworkSettings: {
          status: {
            last_message: 0,
          },
        },
        networkSettings: {
          status: {
            last_message: 0,
          },
        },
      };
    }

    default:
      return state;
  }
}

import { API } from "aws-amplify";
import { getUnitDetails } from "./unit";

export const GET_NMI_DETAILS_REQUEST = "@@site/GET_NMI_DETAILS_REQUEST";
export const GET_NMI_DETAILS_SUCCESS = "@@site/GET_NMI_DETAILS_SUCCESS";
export const GET_NMI_DETAILS_FAILURE = "@@site/GET_NMI_DETAILS_FAILURE";
export const SET_NEW_NMI = "@@site/SET_NEW_NMI";

export function getNmiDetails(nmi, callback) {
  return async (dispatch) => {
    if (!nmi) return;
    dispatch({
      type: GET_NMI_DETAILS_REQUEST,
    });
    try {
      const response = await API.get("cmsAPI", `/site?nmi=${nmi}`);
      dispatch({
        type: GET_NMI_DETAILS_SUCCESS,
        payload: response,
      });
      if (callback) {
        callback();
      }
    } catch (error) {
      if (error.response.status === 404) {
        dispatch({
          type: SET_NEW_NMI,
          payload: nmi,
        });
      } else if (error.response.status === 400) {
        dispatch({
          type: GET_NMI_DETAILS_FAILURE,
        });
      }
    }
  };
}

export const CLEAR_NMI_ERROR = "@@site/CLEAR_NMI_ERROR";

export function clearNmiError() {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_NMI_ERROR,
    });
  };
}

export const GET_SITE_DETAILS_REQUEST = "@@site/GET_SITE_DETAILS_REQUEST";
export const GET_SITE_DETAILS_SUCCESS = "@@site/GET_SITE_DETAILS_SUCCESS";
export const GET_SITE_DETAILS_FAILURE = "@@site/GET_SITE_DETAILS_FAILURE";

export function getSiteDetails(id) {
  return async (dispatch) => {
    if (!id) return;
    dispatch({
      type: GET_SITE_DETAILS_REQUEST,
    });
    try {
      const response = await API.get("cmsAPI", `/site/${id}`);
      dispatch({
        type: GET_SITE_DETAILS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_SITE_DETAILS_FAILURE,
        payload: error,
      });
    }
  };
}

export const POST_SITE_REQUEST = "@@site/POST_SITE_REQUEST";
export const POST_SITE_SUCCESS = "@@site/POST_SITE_SUCCESS";
export const POST_SITE_FAILURE = "@@site/POST_SITE_FAILURE";

export function createSite(value) {
  return async (dispatch) => {
    dispatch({
      type: POST_SITE_REQUEST,
    });
    try {
      const body = {
        body: value,
      };
      const response = await API.post("cmsAPI", `/site`, body);
      dispatch({
        type: POST_SITE_SUCCESS,
        payload: response,
      });
      dispatch(getSiteDetails(response.id));
      dispatch(getUnitDetails(value.unit));
    } catch (error) {
      dispatch({
        type: POST_SITE_FAILURE,
        payload: error,
      });
    }
  };
}

export const PUT_SITE_REQUEST = "@@site/PUT_SITE_REQUEST";
export const PUT_SITE_SUCCESS = "@@site/PUT_SITE_SUCCESS";
export const PUT_SITE_FAILURE = "@@site/PUT_SITE_FAILURE";

export function updateSite(id, value) {
  return async (dispatch) => {
    if (!id) return;
    dispatch({
      type: PUT_SITE_REQUEST,
    });
    try {
      const body = {
        body: value,
      };
      const response = await API.put("cmsAPI", `/site/${id}`, body);
      dispatch({
        type: PUT_SITE_SUCCESS,
        payload: response,
      });
      dispatch(getSiteDetails(id));
      dispatch(getUnitDetails(value.unit));
    } catch (error) {
      dispatch({
        type: PUT_SITE_FAILURE,
        payload: error,
      });
    }
  };
}

export const EDIT_WIZARD_STEP = "@@site/EDIT_WIZARD_STEP";

export function setEditWizardStep(value) {
  return async (dispatch) => {
    dispatch({
      type: EDIT_WIZARD_STEP,
      payload: value,
    });
  };
}

export const CLEAR_SITE_DETAILS = "@@site/CLEAR_SITE_DETAILS";

export function clearSiteDetails() {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_SITE_DETAILS,
    });
  };
}

export const CURRENT_WIZARD_STEP = "@@site/CURRENT_WIZARD_STEP";

export function setCurrentWizardStep(value) {
  return async (dispatch) => {
    dispatch({
      type: CURRENT_WIZARD_STEP,
      payload: value,
    });
  };
}

export const SET_ACTIVE_WIZARD_TAB = "@@site/SET_ACTIVE_WIZARD_TAB";

export function setActiveWizardTab(value) {
  return async (dispatch) => {
    dispatch({
      type: SET_ACTIVE_WIZARD_TAB,
      payload: value,
    });
  };
}

export const SET_PROGRESS_WIZARD_TAB = "@@site/SET_PROGRESS_WIZARD_TAB";

export function setProgressWizardTab(value) {
  return async (dispatch) => {
    dispatch({
      type: SET_PROGRESS_WIZARD_TAB,
      payload: value,
    });
  };
}

import {
  GET_UNIT_DETAILS_REQUEST,
  GET_UNIT_DETAILS_SUCCESS,
  GET_UNIT_DETAILS_FAILURE,
  PUT_UNIT_REQUEST,
  PUT_UNIT_SUCCESS,
  PUT_UNIT_FAILURE,
  CLEAR_UNIT_DETAILS,
  GET_UNIT_STATUS_REQUEST,
  GET_UNIT_STATUS_SUCCESS,
  GET_UNIT_STATUS_FAILURE,
  GET_UNIT_EVENTS_SUCCESS,
  CLEAR_UNIT_EVENTS_SUCCESS,
  GET_OWNER_STATUS_REQUEST,
  GET_OWNER_STATUS_SUCCESS,
  GET_OWNER_STATUS_FAILURE,
  PUT_OWNER_STATUS_REQUEST,
  PUT_OWNER_STATUS_FAILURE,
  PUT_OWNER_STATUS_SUCCESS,
  GET_UNIT_BATTERY_MODULE_REQUEST,
  GET_UNIT_BATTERY_MODULE_SUCCESS,
  GET_UNIT_BATTERY_MODULE_FAILURE,
  PUT_UNIT_BATTERY_MODULE_REQUEST,
  PUT_UNIT_BATTERY_MODULE_FAILURE,
  PUT_UNIT_BATTERY_MODULE_SUCCESS,
} from "actions/unit";

const initialState = {
  loading: false,
  error: {},
  details: {},
  state: "",
  updateLoading: false,
  status: {},
  statusLoading: false,
  events: [],
  pictureDetails: {},
  ownerLoading: false,
  ownerDetails: {},
  batteryModule: [],
};

export default function unit(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_UNIT_DETAILS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_UNIT_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        details: payload,
      };
    }

    case GET_UNIT_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_OWNER_STATUS_REQUEST:
    case PUT_OWNER_STATUS_REQUEST: {
      return {
        ...state,
        ownerLoading: true,
      };
    }

    case GET_OWNER_STATUS_SUCCESS:
    case PUT_OWNER_STATUS_SUCCESS: {
      return {
        ...state,
        ownerLoading: false,
        ownerDetails: payload,
      };
    }

    case GET_OWNER_STATUS_FAILURE:
    case PUT_OWNER_STATUS_FAILURE: {
      return {
        ...state,
        ownerLoading: false,
      };
    }

    case PUT_UNIT_REQUEST: {
      return {
        ...state,
        updateLoading: true,
      };
    }

    case PUT_UNIT_SUCCESS: {
      return {
        ...state,
        updateLoading: false,
      };
    }

    case PUT_UNIT_FAILURE: {
      return {
        ...state,
        updateLoading: false,
      };
    }

    case CLEAR_UNIT_DETAILS: {
      return {
        ...state,
        details: {},
        status: {},
        pictureDetails: {},
        state: "",
      };
    }

    case GET_UNIT_STATUS_REQUEST: {
      return {
        ...state,
        statusLoading: true,
      };
    }

    case GET_UNIT_STATUS_SUCCESS: {
      return {
        ...state,
        statusLoading: false,
        status: payload,
        state: payload.state,
        pictureDetails: {
          solar: payload?.solar_power,
          battery: payload?.battery_power,
          grid: payload?.grid_power,
          grid_present: payload?.grid_present,
          online: payload?.online,
          soc: payload?.battery_soc,
        },
      };
    }

    case GET_UNIT_STATUS_FAILURE: {
      return {
        ...state,
        statusLoading: false,
      };
    }

    case GET_UNIT_EVENTS_SUCCESS: {
      return {
        ...state,
        events: payload,
      };
    }

    case CLEAR_UNIT_EVENTS_SUCCESS: {
      return {
        ...state,
        events: [],
      };
    }

    case GET_UNIT_BATTERY_MODULE_REQUEST:
    case PUT_UNIT_BATTERY_MODULE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_UNIT_BATTERY_MODULE_SUCCESS: {
      return {
        ...state,
        loading: false,
        batteryModule: payload,
      };
    }

    case PUT_UNIT_BATTERY_MODULE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_UNIT_BATTERY_MODULE_FAILURE:
    case PUT_UNIT_BATTERY_MODULE_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}

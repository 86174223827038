import {
  GET_EVENTS_REQUEST,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAILURE,
} from "actions/event";

const initialState = {
  loading: false,
  list: [],
};

export default function event(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_EVENTS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_EVENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: payload,
      };
    }

    case GET_EVENTS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}

import {
  GET_NMI_DETAILS_REQUEST,
  GET_NMI_DETAILS_SUCCESS,
  GET_NMI_DETAILS_FAILURE,
  CLEAR_NMI_ERROR,
  GET_SITE_DETAILS_REQUEST,
  GET_SITE_DETAILS_SUCCESS,
  GET_SITE_DETAILS_FAILURE,
  POST_SITE_REQUEST,
  POST_SITE_SUCCESS,
  POST_SITE_FAILURE,
  PUT_SITE_REQUEST,
  PUT_SITE_SUCCESS,
  PUT_SITE_FAILURE,
  EDIT_WIZARD_STEP,
  SET_NEW_NMI,
  CLEAR_SITE_DETAILS,
  CURRENT_WIZARD_STEP,
  SET_ACTIVE_WIZARD_TAB,
  SET_PROGRESS_WIZARD_TAB,
} from "actions/site";

const initialState = {
  loading: false,
  error: {},
  siteDetails: {},
  siteUpdateLoading: false,
  nmiLoading: false,
  nmiDetails: {},
  nmiError: false,
  editWizardStep: 0,
  currentWizardStep: 0,
  activeWizardTab: 0,
  progressWizardTab: [0],
};

export default function site(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_NMI_DETAILS_REQUEST: {
      return {
        ...state,
        nmiLoading: true,
        nmiError: false,
      };
    }

    case GET_NMI_DETAILS_SUCCESS: {
      return {
        ...state,
        nmiLoading: false,
        nmiDetails: payload,
      };
    }

    case GET_NMI_DETAILS_FAILURE: {
      return {
        ...state,
        nmiLoading: false,
        nmiError: true,
      };
    }

    case GET_SITE_DETAILS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_SITE_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        siteDetails: payload,
      };
    }

    case GET_SITE_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case POST_SITE_REQUEST:
    case PUT_SITE_REQUEST: {
      return {
        ...state,
        siteUpdateLoading: true,
      };
    }

    case POST_SITE_SUCCESS:
    case PUT_SITE_SUCCESS: {
      return {
        ...state,
        siteUpdateLoading: false,
      };
    }

    case POST_SITE_FAILURE:
    case PUT_SITE_FAILURE: {
      return {
        ...state,
        siteUpdateLoading: false,
      };
    }

    case CLEAR_NMI_ERROR: {
      return {
        ...state,
        nmiError: false,
      };
    }

    case EDIT_WIZARD_STEP: {
      return {
        ...state,
        editWizardStep: payload,
      };
    }

    case CURRENT_WIZARD_STEP: {
      return {
        ...state,
        currentWizardStep: payload,
      };
    }

    case SET_NEW_NMI: {
      return {
        ...state,
        ...initialState,
        siteDetails: {
          ...initialState.siteDetails,
          nmi: payload,
        },
        editWizardStep: state.editWizardStep,
      };
    }

    case CLEAR_SITE_DETAILS: {
      return {
        ...state,
        siteDetails: {},
      };
    }

    case SET_ACTIVE_WIZARD_TAB: {
      return {
        ...state,
        activeWizardTab: payload,
      };
    }

    case SET_PROGRESS_WIZARD_TAB: {
      return {
        ...state,
        progressWizardTab: payload,
      };
    }

    default:
      return state;
  }
}

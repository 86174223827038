import { Box, Modal as MuiModal, Typography } from "@mui/material";
import { style, header } from "./modalStyles";
import { Close } from "@mui/icons-material";

const Modal = ({
  title,
  open,
  onClose,
  width,
  top,
  left,
  showHeaderBorder = false,
  children,
  fullHeight = false,
}) => {
  return (
    <MuiModal open={open} onClose={onClose}>
      <Box
        id="modal-container"
        sx={(theme) => style(theme, width, top, left, fullHeight)}
        className="custom-scrollbar"
      >
        <Box sx={() => header(showHeaderBorder)}>
          <Typography variant="h5">{title}</Typography>
          <Close onClick={onClose} />
        </Box>
        <Box>{children}</Box>
      </Box>
    </MuiModal>
  );
};

export default Modal;

import { Modal } from "components";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { QRCodeSVG } from "qrcode.react";
import { FormField, Loader } from "empower-energy-owner-components";
import { useEffect, useState } from "react";
import { setupMFA, verifyMFA } from "actions/session";
import { isEmpty } from "lodash";
import { LoadingButton } from "@mui/lab";

const styles = () => {
  return {
    minHeight: "200px",
    "& .loader": {
      display: "flex",
      alignItems: "center",
    },
    "& .mfa-section": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      "& .mfa-code": {
        px: 2,
        py: 1,
        backgroundColor: "#004057",
        color: "white",
      },
    },

    "& .mui-field": {
      minHeight: "78px",
    },
  };
};

const MFASetupModal = ({ open, onClose }) => {
  const dispatch = useDispatch();

  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");

  const user = useSelector((state) => state.session?.user);
  const mfaSetupLoading = useSelector(
    (state) => state.session?.mfaSetupLoading
  );
  const mfaSetupCode = useSelector((state) => state.session?.mfaSetupCode);
  const mfaVerifyLoading = useSelector(
    (state) => state.session?.mfaVerifyLoading
  );

  useEffect(() => {
    if (user) {
      dispatch(
        setupMFA((res) => {
          if (!res) {
            onClose();
          }
        })
      );
    }
  }, [user]);

  const onChange = (e) => {
    setOtp(e.target.value);
    onBlur(e);
  };

  const onBlur = (e) => {
    setOtpError(
      isEmpty(e.target.value)
        ? "Required"
        : e.target.value.length != 6
        ? "OTP Should be 6 digit"
        : ""
    );
  };

  const onClickVerify = () => {
    dispatch(
      verifyMFA(otp, () => {
        onClose();
      })
    );
  };

  return (
    <Modal
      width="400px"
      top="10%"
      title="Setup Multi-Factor Authentication"
      open={open}
      onClose={onClose}
      showHeaderBorder={true}
    >
      <Box mt={4} sx={styles}>
        {mfaSetupLoading ? (
          <Box className="loader">
            <Loader show={true} />
          </Box>
        ) : (
          mfaSetupCode && (
            <Box className="mfa-section">
              <QRCodeSVG
                value={`otpauth://totp/AWSCognito:${user.username}?secret=${mfaSetupCode}&issuer=Cognito`}
              />
              <Typography my={3} variant="subtitle1">
                Scan QR Code in your authenticator app.
              </Typography>

              <Box mb={2}>
                <Typography variant="subtitle1">
                  Enter generated OTP to verify the setup
                </Typography>
                <FormField
                  name="ssid"
                  value={otp}
                  variant="filled"
                  onChange={onChange}
                  autoComplete="off"
                  error={otpError}
                  onBlur={onBlur}
                />
              </Box>

              <LoadingButton
                sx={{ mb: 2 }}
                onClick={onClickVerify}
                variant="contained"
                loading={mfaVerifyLoading}
                disabled={otpError || otp.length === 0}
              >
                Verify OTP
              </LoadingButton>
            </Box>
          )
        )}
      </Box>
    </Modal>
  );
};

export default MFASetupModal;

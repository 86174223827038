import { Alert as MuiAlert, AlertTitle, Box, Button } from "@mui/material";

const styles = (theme) => {
  return {
    [theme.breakpoints.down("sm")]: {
      p: "6px 12px",
    },
    "& .error-list": {
      display: "flex",
      alignItems: "center",
      marginBottom: "5px",
      "& .error-list-bullets": {
        display: "flex",
        alignItems: "center",
        marginRight: "8px",
        "& .bullet": {
          width: "5px",
          height: "5px",
          borderRadius: "5px",
          backgroundColor: "#d32f2f",
        },
      },
    },
  };
};

const Alert = ({
  severity,
  message,
  next_steps = [],
  note = "",
  system_checks = [],
  system_message = "",
  actionLabel = "",
  onClickAction = undefined,
}) => {
  return (
    <MuiAlert severity={severity} sx={(theme) => styles(theme)}>
      {system_message && <AlertTitle>{system_message}</AlertTitle>}
      {system_checks.length > 0 && (
        <Box pb={1.5}>
          {system_checks.length > 0 &&
            system_checks.map((item, i) => (
              <Box key={i} className="error-list">
                <Box className="error-list-bullets">
                  {severity === "error" ? (
                    <Box className="bullet" />
                  ) : (
                    `${i + 1}.`
                  )}
                </Box>
                <Box>{item}</Box>
              </Box>
            ))}
        </Box>
      )}
      <AlertTitle>{message}</AlertTitle>
      {next_steps.length > 0 && (
        <Box>
          {next_steps.map((item, i) => (
            <Box key={i} className="error-list">
              <Box className="error-list-bullets">
                {severity === "error" ? (
                  <Box className="bullet" />
                ) : (
                  `${i + 1}.`
                )}
              </Box>
              <Box>{item}</Box>
            </Box>
          ))}
        </Box>
      )}
      {note && <Box mt={2}>NOTE: {note}</Box>}

      {onClickAction && (
        <Box mt={2} className="items-justify-end">
          <Button onClick={onClickAction} variant="contained" color="error">
            {actionLabel}
          </Button>
        </Box>
      )}
    </MuiAlert>
  );
};

export default Alert;

import { Box } from "@mui/material";

const styles = (theme) => {
  return {
    position: "relative",
    height: "400px",
    "& .picture-wrapper": {
      [theme.breakpoints.down("sm")]: {
        position: "absolute !important",
        left: "-40px",
      },
    },
  };
};

const PowerFlowWrapper = ({ my = 3, children }) => {
  return (
    <Box sx={(theme) => styles(theme)} my={my}>
      <Box>{children}</Box>
    </Box>
  );
};

export default PowerFlowWrapper;

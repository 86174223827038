import { Box, Typography, Button } from "@mui/material";
import { headerStyle } from "./styles";
import { useSelector } from "react-redux";
import empowerCircle from "images/empowerCircle.svg";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const page = useSelector((state) => state.page);

  const onClickSignOut = () => {
    navigate("/logout");
  };

  return (
    <Box sx={(theme) => headerStyle(theme)}>
      <Box className="flexBox">
        <img src={empowerCircle} alt="" className="space" />
        <Typography variant="h2">{page?.pageTitle}</Typography>
      </Box>
      <Box className="flexBox">
        <Button
          onClick={onClickSignOut}
          className="signOut"
          variant="outlined"
          size="small">
          Sign out
        </Button>
      </Box>
    </Box>
  );
};

export default Header;

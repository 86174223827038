export const style = (
  theme,
  width = 880,
  top = "50%",
  left = "50%",
  fullHeight = false
) => {
  return {
    position: "absolute",
    top: top,
    left: left,
    width: { xs: "97%", md: width },
    transform: "translate(-" + left + ", -" + top + ")",
    bgcolor: "background.paper",
    padding: { xs: "12px", md: "16px 20px" },
    borderRadius: "8px",
    "&:focus-visible": {
      outline: "unset",
    },
    overflowY: "scroll",
    height: fullHeight ? "98%" : "unset",
    [theme.breakpoints.down("sm")]: {
      overflowY: "scroll",
      height: fullHeight ? "98%" : "unset",
    },
  };
};

export const header = (showHeaderBorder) => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: "8px",
    marginBottom: "8px",
    "& .MuiSvgIcon-root": {
      cursor: "pointer",
    },
    borderBottom: showHeaderBorder ? "1px solid rgba(0, 64, 87, 0.25)" : "none",
  };
};

export const iconWrapper = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "12px",
};

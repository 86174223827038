import { LoadingButton } from "@mui/lab";
import { Box, Button, Typography } from "@mui/material";
import BarcodeScanner from "components/BarcodeScanner";
import Modal from "components/Modal";
import { FormField } from "empower-energy-owner-components";
import { isEmpty } from "lodash";
import { ButtonContainer } from "pages/Home/Wizard/components";
import { useEffect, useState } from "react";

const BatteryModuleScannerModal = ({
  selectedSerial,
  open,
  onClose,
  onSave,
}) => {
  
  const initialErrors = {
    serial: ""
  };
  const [serial, setSerial] = useState("");
  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    if (selectedSerial) {
      setSerial(selectedSerial.value);
    }
  }, [selectedSerial]);

  const onBlur = () => {
    checkErrors(serial);
  };
  
  const onChange = (data) => {
    setSerial(data)
    checkErrors(data);
  };

  const checkErrors = (serial) => {
    let errors = {};
    if (isEmpty(serial)) {
      errors = {
        ...errors,
        ["serial"]: "Required",
      };
    } else if (serial.length != 24) {
      errors = {
        ...errors,
        ["serial"]: "Serial should be 24 characters.",
      };
    }

    setErrors({ ...initialErrors, ...errors });
  };

  const onSaveChanges = () => {
    const e = {
      target: {
        name: selectedSerial.name,
        value: serial,
      },
    };
    onSave(e);
    onClose();
  };

  return (
    <Modal
      width={600}
      top="10%"
      title={`Battery Module ${selectedSerial.name}`}
      open={open}
      onClose={onClose}
      showHeaderBorder={true}
    >
      <Box>
        <FormField
          label="Enter Serial Number"
          name="serial"
          error={errors.serial}
          value={serial}
          variant="filled"
          onBlur={onBlur}
          onChange={(e) => onChange(e.target.value)}
        />

        <Typography my={2} variant="h4">
          OR Scan Barcode
        </Typography>

        <BarcodeScanner onChange={onChange} />

        <ButtonContainer>
          <LoadingButton
            onClick={onSaveChanges}
            variant="contained"
            sx={{ mr: 2 }}
            disabled={isEmpty(serial)}
          >
            Save Changes
          </LoadingButton>
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
        </ButtonContainer>
      </Box>
    </Modal>
  );
};

export default BatteryModuleScannerModal;

import { combineReducers } from "redux";
import session from "./session";
import page from "./page";
import profile from "./profile";
import dashboard from "./dashboard";
import unit from "./unit";
import site from "./site";
import option from "./option";
import verification from "./verification";
import event from "./event";

const rootReducer = combineReducers({
  session,
  page,
  profile,
  dashboard,
  unit,
  site,
  option,
  verification,
  event,
});

export default rootReducer;

export const UNIT_STATUS_STATE = {
  OPERATIONAL: "operational",
};

export const GRID_PHASE_OPTIONS = [
  { value: 1, label: "Single Phase" },
  { value: 3, label: "3-Phase" },
];

export const USED_PHASE_OPTIONS = [
  { value: "a", label: "A" },
  { value: "b", label: "B" },
  { value: "c", label: "C" },
];

export const EXTERNAL_PV_PHASE_OPTIONS = [
  { value: 1, label: "External PV - 1 single phase inverter" },
  { value: 2, label: "External PV - 2 single phase inverters" },
  { value: 3, label: "External PV - 3 phase inverter" },
];

export const DEFAULT_METER_MODEL_ID_ADL400N = 3;

export const ENABLE_BACKUP_OPTIONS = [
  {
    value: false,
    label: "No Backup",
  },
  { value: true, label: "Backup Installed" },
];

export const HOUSE_METER_OPTIONS = [];

export const RESERVED_OPTIONS = [
  { value: 0, label: "0%" },
  { value: 20 },
  { value: 30 },
  { value: 40 },
  { value: 50, label: "50%" },
  { value: 60 },
  { value: 70 },
  { value: 80 },
  { value: 90 },
  { value: 100, label: "100%" },
];

export const EXPORT_LIMIT_OPTIONS = [
  { value: 0, label: "0W" },
  { value: 1000 },
  { value: 2000 },
  { value: 3000 },
  { value: 4000 },
  { value: 5000, label: "5000W" },
  { value: 6000 },
  { value: 7000 },
  { value: 8000 },
  { value: 9000 },
  { value: 10000, label: "10000W" },
];

export const GENERATION_LIMIT_OPTIONS = [
  { value: 0, label: "0VA" },
  { value: 1000 },
  { value: 2000 },
  { value: 3000 },
  { value: 4000 },
  { value: 5000, label: "5000VA" },
  { value: 6000 },
  { value: 7000 },
  { value: 8000 },
  { value: 9000 },
  { value: 10000, label: "10000VA" },
];

export const WGRA_OPTIONS = [
  { value: 0, label: "0%" },
  { value: 10 },
  { value: 16.67, label: "16.67%" },
  { value: 20 },
  { value: 30 },
  { value: 40 },
  { value: 50, label: "50%" },
  { value: 60 },
  { value: 70 },
  { value: 80 },
  { value: 90 },
  { value: 100, label: "100%" },
];

export const REACTIVE_POWER_OPTIONS = [
  { value: 0, label: "0%" },
  { value: 0.05 },
  { value: 0.1 },
  { value: 0.15 },
  { value: 0.2 },
  { value: 0.25 },
  { value: 0.3, label: "30%" },
  { value: 0.35 },
  { value: 0.4 },
  { value: 0.45 },
  { value: 0.5 },
  { value: 0.55 },
  { value: 0.6, label: "60%" },
];

export const POWER_FACTOR_OPTIONS = [
  { value: 0.6, label: "0.6" },
  { value: 0.65 },
  { value: 0.7 },
  { value: 0.75 },
  { value: 0.8, label: "0.8" },
  { value: 0.85 },
  { value: 0.9 },
  { value: 0.95 },
  { value: 1.0, label: "1.0" },
];

export const VOLTAGE_RESPONSE_MODES_OPTIONS = [
  { value: "reactivePower", label: "Reactive Power" },
  { value: "powerFactor", label: "Power Factor" },
];

export const POWER_RESPONSE_MODES_OPTIONS = [
  { value: -1, label: "Absorbing" },
  { value: 1, label: "Supplying" },
];

export const WIZARD_HEADER_STEPS = {
  SITE_DETAILS: 0,
  VERIFICATION: 1,
  SUMMARY: 2,
};

export const SITE_DETAILS_STEPS = {
  NMI: 1,
  SITEDETAILS: 2,
  OWNERACCOUNT: 3,
  DNSP: 4,
  BACKUP: 5,
  ASGRID: 6,
  PV: 7,
  BATTERYMODULES: 8,
  VERIFICATION: 9,
};

export const SITE_DETAILS_NEXT_STEPS = {
  NMI: "configure_nmi",
  SITEDETAILS: "configure_site_address",
  OWNERACCOUNT: "owner_account",
  DNSP: "configure_dnsp_and_phase",
  BACKUP: "configure_backup",
  ASGRID: "configure_unit_as4777",
  PV: "configure_unit_pv",
  BATTERYMODULES: "assign_battery_modules",
  VERIFICATION: "complete",
};

export const SITE_DETAILS_STEPS_LIST = [
  { key: "", step: 1 },
  { key: "configure_site_address", step: 2 },
  { key: "owner_account", step: 3 },
  { key: "configure_dnsp_and_phase", step: 4 },
  { key: "configure_backup", step: 5 },
  { key: "configure_unit_as4777", step: 6 },
  { key: "configure_unit_pv", step: 7 },
  { key: "assign_battery_modules", step: 8 },
  { key: "complete", step: 9 },
];

export const VERIFICATION_STEP = {
  INITIAL_VERIFICATION: "initial_verification",
  METER_VERIFICATION: "meter_verification",
  EXTERNAL_PV_VERIFICATION: "external_pv_meter_verification",
  PV_VERIFICATION: "pv_verification",
  BACKUP_CIRCUIT_VERIFICATION: "backup_verification",
  COMMISIONING: "commissioning",
  FLEXIBLE_EXPORTS: "flexible_exports",
  CALIBRATION: "calibration",
};

export const VERIFICATION_STATUS = {
  required: "required",
  notRequired: "not_required",
  pending: "pending",
  readyToStart: "ready_to_start",
  preconditionFailed: "precondition_failed",
  inProgress: "in_progress",
  failed: "failed",
  completed: "completed",
  postponed: "postponed",
};

export const SUMMARY_STEP = {
  CALIBRATION: 3,
  COMMISIONING: 4,
};

export const SUMMARY_INSTALLED_INSTRUCTIONS = {
  message: "The Electrobank unit has been successfully configured",
  next_steps: [
    "The unit has automatically initiated the Start of Life Calibration process.",
  ],
};

export const SUMMARY_INSTALLED_VERIFIED_INSTRUCTIONS = {
  message: "The Electrobank unit has been successfully configured",
  next_steps: [
    "The unit has automatically initiated the Start of Life Calibration process.",
    "Ensure the customer can successfully log in to the Elektrobank app",
    "Guide the customer through the key features of their new battery. Please note that normal operation will commence after completion of the automated Start of Life calibration cycle.",
  ],
};

export const SUMMARY_NOT_CONFIGURED_INSTRUCTIONS = {
  message: "An owner account has not been configured",
  next_steps: [
    "Provide a customer email address - a login request will be sent to their account",
    "Ensure the customer can successfully verify their account and log in to the Elektrobank app",
    "Guide the customer through the key features of their new battery. Please note that normal operation will commence after completion of the automated Start of Life calibration cycle.",
  ],
};

export const SUMMARY_NOT_VERIFIED_INSTRUCTIONS = {
  message: "An owner account has not been verified",
  next_steps: [
    "Ensure the customer can successfully verify their account and log in to the Elektrobank app",
    "Guide the customer through the key features of their new battery. Please note that normal operation will commence after completion of the automated Start of Life calibration cycle.",
  ],
};

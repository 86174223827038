import { Box, Button } from "@mui/material";
import { assignUnit } from "actions/dashboard";
import { Modal } from "components";
import { FormField } from "empower-energy-owner-components";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const styles = () => {
  return {
    mt: 2,
    "& .mui-field": {
      display: "block",
      minHeight: "80px",
      "& .MuiFormGroup-root": {
        justifyContent: "center",
        "& .MuiFormControlLabel-root": {
          width: "200px",
        },
      },
    },
    "& .button-footer": {
      mt: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
    },
  };
};

const AddUnitModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    serial: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    serial: "",
    password: "",
  });

  const addLoading = useSelector((state) => state.dashboard?.addLoading);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    onBlur(e);
  };

  const onBlur = (e) => {
    const newErrors = {
      ...errors,
      [e.target.name]: isEmpty(e.target.value) ? "Required" : "",
    };
    setErrors(newErrors);

    if (e.target.name === "password" && e.target.value.length !== 16) {
      setErrors({
        ...errors,
        [e.target.name]: "Password should be 16 characters long",
      });
    }

    if (e.target.name === "serial" && e.target.value.length !== 12) {
      setErrors({
        ...errors,
        [e.target.name]: "Serial should be 12 characters long",
      });
    }
  };

  const onClickAdd = () => {
    dispatch(
      assignUnit(formData, () => {
        onClose(false);
      })
    );
  };

  const isDisabled = () =>
    Object.values(formData).every((it) => isEmpty(it)) ||
    !Object.values(errors).every((it) => isEmpty(it));

  return (
    <Modal
      width="520px"
      top="10%"
      title="Enter Elektrobank details"
      open={open}
      onClose={onClose}
      showHeaderBorder={true}
    >
      <Box sx={styles}>
        <FormField
          label="Serial"
          name="serial"
          value={formData.serial}
          variant="filled"
          onChange={onChange}
          autoComplete="off"
          error={errors.serial}
          onBlur={onBlur}
          disabled={addLoading}
        />
        <FormField
          label="Password"
          name="password"
          type="password"
          value={formData.password}
          variant="filled"
          onChange={onChange}
          autoComplete="off"
          error={errors.password}
          onBlur={onBlur}
          disabled={addLoading}
        />
        <Box className="button-footer">
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onClickAdd} disabled={addLoading || isDisabled()}>
            Add
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddUnitModal;

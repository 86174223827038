import { Box } from "@mui/material";

const Divider = () => {
  return (
    <Box
      sx={{
        width: "100%",
        my: 1,
        borderBottom: "1px solid rgba(0, 64, 87, 0.25)",
      }}
    ></Box>
  );
};

export default Divider;

import { lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { lazyRetry } from "utils/lazy";

import BasicLayout from "layout/BasicLayout";
import AuthLayout from "layout/AuthLayout";

const Login = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: "Login" */ "./pages/Session/Login")
  )
);

const Logout = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: "Logout" */ "./pages/Session/Logout")
  )
);

const ResetPassword = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "ResetPassword" */ "./pages/Session/ResetPassword"
    )
  )
);

const ChangePassword = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "ChangePassword" */ "./pages/Session/ChangePassword"
    )
  )
);

const FirstLogin = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: "FirstLogin" */ "./pages/Session/FirstLogin")
  )
);

const Home = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "Home" */ "./pages/Home"))
);

const Settings = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "Settings" */ "./pages/Settings"))
);

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <BasicLayout />,
      children: [
        { path: "", element: <Login /> },
        { path: "change-password", element: <ChangePassword /> },
        { path: "first-login", element: <FirstLogin /> },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "logout", element: <Logout /> },
      ],
    },
    {
      path: "/home",
      element: <AuthLayout />,
      children: [{ path: "", element: <Home pageTitle="Home" /> }],
    },
    {
      path: "/settings",
      element: <AuthLayout />,
      children: [{ path: "", element: <Settings pageTitle="Settings" /> }],
    },
    { path: "*", element: <Navigate to="/" replace /> },
  ]);
}

import { API } from "aws-amplify";

export const GET_EVENTS_REQUEST = "@@event/GET_EVENTS_REQUEST";
export const GET_EVENTS_SUCCESS = "@@event/GET_EVENTS_SUCCESS";
export const GET_EVENTS_FAILURE = "@@event/GET_EVENTS_FAILURE";

export function getEvents() {
  return async (dispatch) => {
    dispatch({
      type: GET_EVENTS_REQUEST,
    });
    try {
      const response = await API.get("cmsAPI", `/event`);
      dispatch({
        type: GET_EVENTS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_EVENTS_FAILURE,
        payload: error,
      });
    }
  };
}

import {
  GET_PROFILE_DETAIL_REQUEST,
  GET_PROFILE_DETAIL_SUCCESS,
  GET_PROFILE_DETAIL_FAILURE,
  CLEAR_PROFILE,
} from "actions/profile";

const initialState = {
  loading: false,
  error: {},
  email: "",
  first_name: "",
  last_name: "",
  phone: "",
  units: [],
};

export default function profile(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PROFILE_DETAIL_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_PROFILE_DETAIL_SUCCESS: {
      return {
        ...state,
        loading: false,
        ...payload,
        units: payload.units.map((it) => {
          return {
            ...it,
            value: it.unit,
            label: it.name,
          };
        }),
      };
    }

    case GET_PROFILE_DETAIL_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case CLEAR_PROFILE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
}
